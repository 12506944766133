import { EmailData, EmailRegisterAccountResponse, NewAccountResponse, VerifyCpfCnpjIsValidRequest } from './../../models/auth/auth';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { BaseComponent } from 'src/app/@base/base.component';
import { ModalConfirmComponent } from 'src/app/components/modal/modal-confirm/modal-confirm.component';
import { ModalMessageComponent } from 'src/app/components/modal/modal-message/modal-message.component';
import { EnumPath } from 'src/app/enum/path';
import { CustomValidators } from 'src/app/form/custom-validators';
import { NewAccount, VerifyCpfCnpjIsValidResponse } from 'src/app/models/auth/auth';
import { Table } from 'src/app/models/table';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-new-account',
  templateUrl: './new-account.component.html',
  styleUrls: ['./new-account.component.scss'],
})
export class NewAccountComponent extends BaseComponent implements OnInit {
  public hidePassword: boolean = true;
  public hideConfirmPassword: boolean = true;
  public form: FormGroup;
  public acceptTerms: boolean = false;
  public termsRead: boolean = false;
  public today = new Date();
  public loading: boolean = false;
  public loadingVerify: boolean = false;
  public emails: string[];
  public hiddenEmails: string[];
  public selectedEmail: string;
  public email: string = '';
  public emailValidado: boolean;

  get passwordMatchError() {
    return (
      this.form.getError('mismatch') &&
      this.form.get('confirmPassword')?.touched
    );
  }

  get labelDate() {
    return this.form.get('document').value?.length > 11
      ? 'Data de constitução'
      : 'Data de nascimento';
  }

  get labelDocument() {
    return this.form.get('document').value?.length > 11 ? 'CNPJ' : 'CPF';
  }

  constructor(
    private formBuilder: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();
  }

  public createForm(): void {
    this.form = this.formBuilder.group({
      document: [
        null,
        [
          Validators.required,
          CustomValidators.isValidCpf(),
          CustomValidators.isValidCNPJ(),
        ],
      ],
      email:[null, [Validators.required]]
    });
  }

  public goToLoginPage(): void {
    if (this.form.dirty) {
      firstValueFrom(
        this.openDialogConfirm('Tem certeza que deseja voltar?').afterClosed()
      ).then((resp: boolean) => {
        if (resp) this.router.navigate([EnumPath.LOGIN]);
      });
      return;
    }
    this.router.navigate([EnumPath.LOGIN]);
  }

  public post(): void {
    this.loading = true;

    var isEmailValid = this.VerifyInputEmailChange();

    if(!isEmailValid){
      this.openDialogError("O E-mail digitado não é o mesmo que foi selecionado.", "Atenção");
      this.loading = false;
      return;
    }
    firstValueFrom(this.authService.sendEmailRegisterAccount(this.form.value))
      .then((resp: EmailRegisterAccountResponse) => {
        firstValueFrom(
          this.openDialogSucess(`Você acabou de receber um link de confirmação no e-mail ${resp.sentTo}`).afterClosed()
        ).then(() => this.router.navigate([EnumPath.LOGIN]));
      })
      .finally(() => (this.loading = false))
      .catch((error: HttpErrorResponse) => {
        if (error.status !== 500) {
          this.openDialogError(error, 'Atenção!');
        }
      });
  }

  public onScroll(event: any): void {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight - 300
    ) {
      this.termsRead = true;
    }
  }

  onEmailSelect(event: MatSelectChange) {
    this.selectedEmail = event.value;
  }

  public verifyCpfCnpj(){
    this.loadingVerify = true
    this.emails = null;
    let documentCpfCnpj = this.form.get('document');

    documentCpfCnpj?.markAsTouched();
    documentCpfCnpj?.updateValueAndValidity();

    let verify: VerifyCpfCnpjIsValidRequest = {
      document : documentCpfCnpj.value,
    }

    if(documentCpfCnpj.valid){
      firstValueFrom(this.authService.verifyCpfCnpjIsValid(verify))
      .then((resp) => {
        if(resp){
          this.emails = resp.map(response => response.email).flat();
          this.hiddenEmails = resp.map(response => response.hiddenEmail).flat();
        }
      })
      .finally(() => (this.loadingVerify = false))
      .catch((error: HttpErrorResponse) => {
        if (error.status !== 500) {
          this.openDialogError(error.error.message, "Atenção");
        }
      });
    }
    else{
      this.loadingVerify = false;
    }
  }

  VerifyInputEmailChange(): boolean {
    const index = this.hiddenEmails.indexOf(this.selectedEmail);
    if (index !== -1) {
      return this.emailValidado = this.email === this.emails[index];
    } else {
      return this.emailValidado = false;
    }
  }

  notRecognizeEmail(){
    this.openDialogMessage(
      "Caso não reconheça nenhum e-mail(s) vinculado(s), favor entrar em contato com a nossa Central de Relacionamento (11) 2088-7700",
    );
  }
}
