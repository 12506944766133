import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { BaseComponent } from 'src/app/@base/base.component';
import { EnumPath } from 'src/app/enum/path';
import { ConfirmAccount } from 'src/app/models/auth/auth';

@Component({
  selector: 'app-confirm-user-account',
  templateUrl: './confirm-user-account.component.html',
  styleUrls: ['./confirm-user-account.component.scss']
})
export class ConfirmUserAccountComponent extends BaseComponent implements OnInit {
  public hidePassword: boolean = true;
  public acceptTerms: boolean = false;
  public termsRead: boolean = false;
  public today = new Date();
  public loading: boolean = false;
  public loadingVerify: boolean = false;
  public emails: string[];
  public hiddenEmails: string[];
  public selectedEmail: string;
  public email: string = '';
  public emailValidado: boolean;

  constructor(
    private activatedRoute : ActivatedRoute,
  ) {
    super();
  }

  ngOnInit(): void {
    this.confirmAccount();
  }

  public goToLoginPage(): void {
    this.router.navigate([EnumPath.LOGIN])
  }


  get documentUser(){
    return this.activatedRoute.snapshot.paramMap.get("documentUser")
  }

  get guid(){
    return this.activatedRoute.snapshot.paramMap.get("guid")
  }

  public confirmAccount(): void {

    let emailConfirm: ConfirmAccount = {
      document: this.documentUser,
      guid: this.guid
    }

    firstValueFrom(this.authService.confirmAccount(emailConfirm))
      .then(() => {
            firstValueFrom(
              this.openDialogSucess('E-mail validado com sucesso!').afterClosed()
            ).then(() => this.router.navigate([EnumPath.LOGIN]));
          })
      .finally(() => (this.loading = false))
      .catch((error: HttpErrorResponse) => { if(error.status != 500) this.openDialogError(error, 'Atenção!'); })
  }
}
