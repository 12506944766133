export enum EnumPath {
  LOGIN = "/login",
  NEW_ACCOUNT = "/register-password-new-account",
  NEW_ACCOUNT_old= "/new-account-old",
  RECOVER = "/new-password",
  EXPIRED_PASSWORD = "/reset-expired-password",
  EXPIRED_PASSWORD_MESSAGE = "/expired-password-message",
  HOME = "/home",
  CONSORTIUM = "/consortium",
  SETTINGS = "/settings",
  SETTINGS_MESSAGES = "/settings/messages",
  SETTINGS_MESSAGES_CREATE = "/settings/messages/create",
  SETTINGS_MESSAGES_UPDATE = "/settings/messages/update",
  SETTINGS_MESSAGES_FILTER = "/settings/messages/search",
  SETTINGS_BANNERS = "/settings/banners",
  SETTINGS_BANNERS_FILTER = "/settings/banners/search",
  SETTINGS_BILLING_CONSULTANCIES = "/settings/billing-consultancies",
  SETTINGS_BILLING_CONSULTANCIES_CREATE = "/settings/billing-consultancies/create",
  SETTINGS_BILLING_CONSULTANCIES_UPDATE = "/settings/billing-consultancies/update",
  SETTINGS_BILLING_CONSULTANCIES_FILTER = "/settings/billing-consultancies/search",
  SETTINGS_BANNERS_CREATE = "/settings/banners/create",
  SETTINGS_BANNERS_UPDATE = "/settings/banners/update",
  SETTINGS_USERS = "/settings/users",
  SETTINGS_USERS_FILTER = "/settings/users/search",
  SETTINGS_USERS_CREATE = "/settings/users/create",
  SETTINGS_USERS_UPDATE = "/settings/users/update",
  SETTINGS_PROFILE = "/settings/profile",
  SETTINGS_PROFILE_CREATE = "/settings/profile/create",
  SETTINGS_PROFILE_UPDATE = "/settings/profile/update",
  SETTINGS_PROFILE_FILTER = "/settings/profile/search",
  SETTINGS_PARAMETER = "/settings/parameters",
  SETTINGS_PARAMETER_UPDATE = "/settings/parameters/update",
  SETTINGS_PARAMETER_FILTER = "/settings/parameters/search",
  SETTINGS_CONSUMPTION_REPORT = "/settings/consumption-report",
  SETTINGS_CONSUMPTION_REPORT_FILTER = "/settings/consumption-report/search",
  BANK = "/bank",
  BANK_PAYMENT = "/bank/second-payment",
  BANK_PAYMENT_BOOKLET = "/bank/payment",
  BANK_BILL = "/bank/second-payment",
  BANK_CONTRACT = "/bank/contract",
  BANK_PAYMENT_BOOK = "/bank/payment-book",
  BANK_STATEMENT = "/bank/statement",
  BANK_ANUAL_STATEMENT = "/bank/anual-statement",
  BANK_CONTRACT_RECEIPT = "/bank/contract-receipt",
  BANK_CONTRACT_BALANCE_DUE = "/bank/contract-balance-due",
  INSURANCE = "/insurance",
  CREDIT = "/credit",
  REQUESTS = "/requests",
  FOOTER_CONSORTIUM = "https://www.yamahaservicosfinanceiros.com.br/consorcio",
  FOOTER_BANK = "https://www.yamahaservicosfinanceiros.com.br/banco",
  FOOTER_INSURANCE = "https://www.yamahaservicosfinanceiros.com.br/seguros",
  FOOTER_CREDIT = "https://www.yamahaservicosfinanceiros.com.br/liberacred",
  FOOTER_PRIVACY_ADVERTISING = "https://www.yamahaservicosfinanceiros.com.br/aviso-de-privacidade",
  FOOTER_CYBERSECURITY_POLICY = "https://www.yamahaservicosfinanceiros.com.br/politica-de-seguranca-cibernetica",
  FOOTER_QUALITY_ENVIRONMENT = " https://www.yamahaservicosfinanceiros.com.br/gestao-de-qualidade-ambiental",
  FOOTER_REPORTING_CHANNEL = "https://www.yamaha-motor.com.br/canaldedenuncia",
  FOOTER_CODE_ETHICS = "https://www.yamahaservicosfinanceiros.com.br/codigo-de-etica",
  FOOTER_FACEBOOK = "https://www.facebook.com/ConsorcioNacionalYamaha",
  FOOTER_YAMAHA_MOTOR = "https://www.yamaha-motor.com.br/",
  FOOTER_YAMAHA_MUSICAL = "https://br.yamaha.com/index.html",
  FOOTER_YAMAHA_NAUTICA = "https://www.yamaha-nautica.com.br",
  FAQ = "/faq",
  CONTACT = "https://www.yamahaservicosfinanceiros.com.br/contato",
  INSTITUTIONAL_LIBERACRED = "https://www.yamahaservicosfinanceiros.com.br/liberacred",
  INSTITUTIONAL_CONSORTIUM = "https://www.yamahaservicosfinanceiros.com.br/consorcio",
  INSTITUTIONAL_INSURANCE = "https://www.yamahaservicosfinanceiros.com.br/seguros",
  INSTITUTIONAL_BANK = "https://www.yamahaservicosfinanceiros.com.br/banco",
  HOME_LEARN_MORE = "https://yamahaservicosfinanceiros.com.br/",
  DEALERS = "/dealer",
  DEALERS_DEBT_SETTLEMENT = "/dealer/debt-settlement",
}
