import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { NewAccountComponent } from './new-account/new-account.component';
import { ForgotPasswordRecoverComponent } from './forgot-password/forgot-password-recover/forgot-password-recover.component';
import { ForgotPasswordNewPasswordComponent } from './forgot-password/forgot-password-new-password/forgot-password-new-password.component';
import { GuidGuard } from '../guards/guid.guard';
import { ResetExpiredPasswordComponent } from './reset-expired-password/reset-expired-password.component';
import { ExpiredPasswordMessageComponent } from './reset-expired-password/expired-password-message/expired-password-message.component';
import { ExternalLoginComponent } from './external-login/external-login.component';
import { RegisterPasswordNewAccountComponent } from './register-password-new-account/register-password-new-account.component';
import { ConfirmUserAccountComponent } from './confirm-user-account/confirm-user-account.component';
import { NewAccountOldComponent } from './new-account-old/new-account-old.component';
const routes: Routes = [
  {
    path: "",
    component: AuthComponent,
    children: [
      {
        path: "login/external/:guid",
        component: ExternalLoginComponent
      },
      {
        path: "login",
        component: LoginComponent
      },
      {
        path: "new-account",
        component: NewAccountComponent
      },
      {
        path: "recover",
        component: ForgotPasswordRecoverComponent
      },
      {
        path: "new-password/:guid",
        component: ForgotPasswordNewPasswordComponent,
        canActivate: [GuidGuard]
      },
      {
        path: "register-password-new-account/:guid/:documentUser",
        component: RegisterPasswordNewAccountComponent,
        canActivate: [GuidGuard]
      },
      {
        path: "confirm-account/:guid/:documentUser",
        component: ConfirmUserAccountComponent,
        canActivate: [GuidGuard]
      },
      {
        path: "reset-expired-password",
        component: ResetExpiredPasswordComponent
      },
      {
        path:'expired-password-message/:isInternal',
        component: ExpiredPasswordMessageComponent
      },
      {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
      }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
