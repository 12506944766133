import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { BaseComponent } from 'src/app/@base/base.component';
import { EnumPath } from 'src/app/enum/path';
import { CustomValidators } from 'src/app/form/custom-validators';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-new-account',
  templateUrl: './new-account-old.component.html',
  styleUrls: ['./new-account-old.component.scss'],
})
export class NewAccountOldComponent extends BaseComponent implements OnInit {
  public hidePassword: boolean = true;
  public hideConfirmPassword: boolean = true;
  public form: FormGroup;
  public acceptTerms: boolean = false;
  public termsRead: boolean = false;
  public today = new Date();
  public loading: boolean = false;

  get passwordMatchError() {
    return (
      this.form.getError('mismatch') &&
      this.form.get('confirmPassword')?.touched
    );
  }

  get labelDate() {
    return this.form.get('document').value?.length > 11
      ? 'Data de constitução'
      : 'Data de nascimento';
  }

  get labelDocument() {
    return this.form.get('document').value?.length > 11 ? 'CNPJ' : 'CPF';
  }

  constructor(
    private formBuilder: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();
  }

  public createForm(): void {
    this.form = this.formBuilder.group({
      fullName: ['', [Validators.required]],
      password: [
        null,
        [
          Validators.required,
          Validators.pattern(
            '^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*\\W)(?!.* ).{8,50}$'
          ),
        ],
      ],
      confirmPassword: [null, Validators.required],
      document: [
        null,
        [
          Validators.required,
          CustomValidators.isValidCpf(),
          CustomValidators.isValidCNPJ(),
        ],
      ],
      email: [null, [Validators.required, Validators.email]],
      alternativeEmail: [null, [Validators.email]],
      birthDate: [
        null,
        [
          Validators.required,
          CustomValidators.isDateGreaterThanToday(),
          CustomValidators.isAgeAtLeast14Years()
        ],
      ],
      phone: [null, [Validators.required]]
    });

    this.form.setValidators(
      CustomValidators.MatchValidator('password', 'confirmPassword')
    );
  }

  public goToLoginPage(): void {
    if (this.form.dirty) {
      firstValueFrom(
        this.openDialogConfirm('Tem certeza que deseja voltar?').afterClosed()
      ).then((resp: boolean) => {
        if (resp) this.router.navigate([EnumPath.LOGIN]);
      });

      return;
    }

    this.router.navigate([EnumPath.LOGIN]);
  }

  public post(): void {
    this.loading = true;

    firstValueFrom(this.authService.registerOld(this.form.value))
      .then(() => {
        firstValueFrom(
          this.openDialogSucess('Cliente cadastrado com sucesso!').afterClosed()
        ).then(() => this.router.navigate([EnumPath.LOGIN]));
      })
      .finally(() => (this.loading = false))
      .catch((error: HttpErrorResponse) => {
        if (error.status !== 500) {
          this.openDialogError(error, 'Atenção!');
        }
      });
  }

  public onScroll(event: any): void {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight - 300
    ) {
      this.termsRead = true;
    }
  }
}
