import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/@base/base.component';
import { EnumPath } from 'src/app/enum/path';
import { CustomValidators } from 'src/app/form/custom-validators';
import { firstValueFrom } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-register-password-new-account',
  templateUrl: './register-password-new-account.component.html',
  styleUrls: ['./register-password-new-account.component.scss']
})
export class RegisterPasswordNewAccountComponent extends BaseComponent implements OnInit {
  get passwordMatchError() {
    return (
      this.form.getError('mismatch') &&
      this.form.get('confirmPassword')?.touched
    );
  }

  get guid(){
    return this.activatedRoute.snapshot.paramMap.get("guid")
  }
  get documentUser(){
    return this.activatedRoute.snapshot.paramMap.get("documentUser")
  }

  public hidePassword: boolean = true;
  public hideConfirmPassword: boolean = true;
  public form: FormGroup;
  public loading: boolean = false;
  public success: boolean = false;


  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute : ActivatedRoute,
  ){
    super()
  }

  ngOnInit(): void {
    this.createForm();
  }

  public createForm(): void {
    this.form = this.formBuilder.group({
      password: [null, [Validators.required, Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*\\W)(?!.* ).{8,50}$')]],
      confirmPassword: [null, Validators.required],
      guid: this.guid,
      document: this.documentUser
    })

    this.form.setValidators(CustomValidators.MatchValidator('password', 'confirmPassword'))
  }

  public goToLoginPage(): void {
    this.router.navigate([EnumPath.LOGIN]);
  }

  public change(): void {
    this.loading = true;
    firstValueFrom(this.authService.register(this.form.value))
      .then(() => {
            firstValueFrom(
              this.openDialogSucess('Cliente cadastrado com sucesso!').afterClosed()
            ).then(() => this.router.navigate([EnumPath.LOGIN]));
          })
      .finally(() => (this.loading = false))
      .catch((error: HttpErrorResponse) => { if(error.status != 500) this.openDialogError(error.error.message, "Atenção"); })
  }
}
