<div class="content density-lower backdrop-blur">
  <div class="content-header">
    <span class="uppercase estricta bolder content-header-title"> Cadastro </span>
    <span class="content-header-subtitle"> Preencha corretamento o formulário abaixo com os respectivos dados. </span>
  </div>

  <form [formGroup]="form" class="content-form-fields density-mat-form-3">

    <div class="flex-line">

      <div class="form-field">
        <label> <span class="red">*</span> CPF / CNPJ </label>
        <mat-form-field appearance="outline" class="background-white">
          <input type="text" matInput maxlength="18" formControlName="document"
            mask="000.000.000-00||00.000.000/0000-00" data-cy="document">
          <mat-error *ngIf="form.get('document').hasError('required')" data-cy="error-document-required">
            <strong> O CPF/CNPJ é obrigatório </strong>
          </mat-error>

          <mat-error *ngIf="form.get('document').hasError('mask')" data-cy="error-document-format-invalid">
            <strong> {{labelDocument}} informado é inválido </strong>
          </mat-error>

          <mat-error
            *ngIf="form.get('document').hasError('cpfNotValid') || form.get('document').hasError('cnpjNotValid')"
            data-cy="error-document-value-invalid">
            <strong> {{labelDocument}} informado é inválido </strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-field">
        <app-button type="submit" width="100%" color="primary" [disabled]="loadingVerify" [loading]="loadingVerify" datacy="button-search"
          (onClick)="verifyCpfCnpj()">
          <span class="text-size"> Validar</span>
        </app-button>
      </div>
    </div>

    <div class="form-field">
      <label> <span class="red">*</span> Selecione o e-mail</label>
      <mat-form-field appearance="outline" class="background-white" subscriptSizing="dynamic">
        <mat-select placeholder="Selecione o e-mail" [(value)]="selectedEmail"
          (selectionChange)="onEmailSelect($event)">
          <mat-option *ngFor="let hiddenEmail of hiddenEmails" [value]="hiddenEmail">{{ hiddenEmail }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="content-header-subtitle-recognize">
        <span class="cursor-pointer underline" (click)="notRecognizeEmail()" data-cy="new-account"> Clique aqui caso não reconheça os e-mail´s listados.
        </span>
      </div>
    </div>

    <div class="form-field">
      <label> <span class="red">*</span> Insira o e-mail selecionado: </label>
      <mat-form-field appearance="outline" class="background-white">
        <input matInput id="email-input" type="text" [(ngModel)]="email"
          formControlName="email" />
        <mat-error *ngIf="form.get('email').hasError('required')" data-cy="error-document-required">
          <strong> O e-mail é obrigatório </strong>
        </mat-error>
      </mat-form-field>
    </div>
  </form>

  <div class="flex-vertical-center content-terms" data-cy="terms">
    <span class="uppercase estricta bolder content-terms-title"> Termos e condições de uso do portal yamaha </span>

    <div class="content-terms-content flex-vertical-center">

      <div class="content-terms-text" (scroll)="onScroll($event)" data-cy="terms-content">

        <div class="flex-vertical-center content-terms-text-item">
          <span class="estricta uppercase bolder"> Política de Privacidade </span>
          <span> O Grupo Yamaha Motor do Brasil possui um compromisso com a segurança e privacidade de seus usuários.
            Sendo assim, apresentamos abaixo a nossa política de privacidade. </span>
        </div>

        <div class="flex-vertical-center content-terms-text-item">
          <span class="estricta uppercase bolder"> Aplicabilidade </span>
          <span> Os princípios estipulados nesta Política de Privacidade se aplicam a todos aqueles cuja incumbência é
            controlar esse tipo de informação no Grupo Yamaha Motor do Brasil. </span>
        </div>

        <div class="flex-vertical-center content-terms-text-item">
          <span class="estricta uppercase bolder"> Sua privacidade </span>
          <span> Na Yamaha acreditamos que você, nosso visitante online, tem o direito de saber como cuidamos das
            informações que obtemos quando você visita nossas páginas, e o tipo de informação que recolhemos. As
            premissas que apresentaremos referem-se a suas visitas dos sites operados pelas subsidiárias da Yamaha
            Corporation Ltda. </span>
          <span> Outros sites com links de/para este site (incluindo outros afiliados da Yamaha e links por banners)
            podem ter outras práticas, sendo assim, verifique os avisos de política de privacidade, destes outros sites,
            para detalhes. </span>
        </div>

        <div class="flex-vertical-center content-terms-text-item">
          <span class="estricta uppercase bolder"> Coleta de informações: cookies e personalização </span>
          <span> Para cada visitante de um site Yamaha, o servidor da Yamaha reconhece e armazena informações como o
            endereço de IP/nome do domínio. Usamos esta informação para entender o padrão de tráfego de visitas pelos
            nossos sites, com objetivo de melhorar o funcionamento. O seu computador fornece esta informação
            automaticamente cada vez que você se conecta, a não ser que você ative a tecnologia de bloqueio destas
            informações, como pode ser feito em alguns navegadores. Além disso, a Yamaha pode solicitar "informações
            pessoais" que são informações dadas por você através do site, como seu nome, endereço de e-mail, ou endereço
            real, número de telefone, número de documentos, número de cartão de crédito ou outras formas de
            identificação permanente (como um número que seja conservado por um “cookie”) que pode ser associado com
            informações de identificação pessoal, permitindo que você participe de concursos, salas de bate-papo,
            painéis de informações, faça registro ou encomende produtos e serviços usando estes sites. A Yamaha pode
            combinar as informações fornecidas pelo site com outras informações recolhidas de você ou de terceiros, para
            uma programação mais eficiente do nosso site, serviços e formas de atendimento em relação a você. Se você
            fornecer informações pessoais para a Yamaha, você deve ter o direito, com um aviso prévio razoável, de
            inspecionar, mudar, acrescentar ou eliminar estas informações. As instruções de como atualizar informações
            pessoais são fornecidas durante o procedimento em que o dado é obtido. Se você não solicitar o contrário, a
            Yamaha pode mandar informações sobre produtos, serviços, promoções, concursos, e outras atividades ou
            ofertas que possam ser do seu interesse. Você pode nos informar se não deseja ser contatado marcando ou
            desmarcando as caixas de opções que indicam as preferências aplicadas ao local em que suas informações foram
            recolhidas, ou por outros meios, (por exemplo, solicitação por e-mail). Além destas opções, em cada
            comunicação que você receber da Yamaha, você terá a oportunidade de indicar se você não quiser mais receber
            comunicações futuras. </span>
          <span> A menos que seja um objetivo de sua solicitação ou do seu acordo original, a Yamaha não compartilha as
            informações pessoais que você fornece nos sites Yamaha, para terceiros, exceto (a) com o seu consentimento,
            (b) como possa ser permitido ou solicitado por lei ou ordem judicial e/ou (c) para pessoas ou empresas com
            as quais a Yamaha tenha contrato para operações internas do site ou negócios (por exemplo, para enviar itens
            promocionais que você tenha solicitado através do site). Adicionalmente, e com o seu consentimento, a Yamaha
            pode compartilhar suas informações pessoais com outras empresas afiliadas com empresas Yamaha (ou seja, (I)
            Yamaha Corporation, (II) qualquer empresa em que mais de 50% das ações sejam de propriedade da Yamaha
            Corporation, direta ou indiretamente, ou (III) qualquer empresa em que o nome "Yamaha" seja parte de sua
            identificação) que possa querer contatá-lo sobre produtos, serviços ou outras ofertas que possam ser do seu
            interesse. Além disso, apenas com o seu consentimento, a Yamaha pode compartilhar suas informações com
            terceiros, fora do grupo de empresas Yamaha, para que entrem em contato com você sobre produtos, serviços ou
            outras ofertas que possam ser de seu interesse. Por favor, verifique as políticas de privacidade de cada uma
            destas empresas, para clareza quanto a políticas de privacidades das mesmas. A Yamaha irá procurar obter seu
            consentimento em situações em que a informação é recolhida por métodos "opt-out" ou "opt-in", ou outros
            meios (como solicitação por e-mail). Se você aceitar receber o contato de qualquer destas empresas afiliadas
            à Yamaha, ou terceiros, você precisará de uma comunicação direta com estas empresas quando mais tarde,
            resolver que não deseja mais receber suas comunicações. Até você nos comunicar que não deseja mais receber
            informações e contatos (exceto quando seu contato inicial já não permitir o compartilhamento de suas
            informações com afiliados e terceiros). As mudanças, alterações e acréscimos, feitos nas suas informações
            nos sites Yamaha e afiliados Yamaha não são compartilhados entre a Yamaha, afiliados ou terceiros. </span>
        </div>

        <div class="flex-vertical-center content-terms-text-item">
          <span class="estricta uppercase bolder"> Novidades no seu e-mail </span>
          <span> Na hora de se cadastrar no Portal da Yamaha, você pode optar por receber as novidades em seu e-mail. No
            cadastro, você também habilita o envio de informações e promoções do Grupo Yamaha e nossos parceiros. Caso
            você não queira receber essas mensagens, basta clicar no “link” que aparece no fim de cada e-mail enviado
            pelo portal. </span>
        </div>

        <div class="flex-vertical-center content-terms-text-item">
          <span class="estricta uppercase bolder"> Utilização do Site </span>
          <span> O visitante/usuário reconhece que é responsável por quaisquer informações falsas que possam ser
            prestadas para a utilização do Site. O Usuário isenta o Grupo Yamaha de qualquer responsabilidade quanto à
            veracidade dos dados pessoais fornecidos por você quando do uso do Site. </span>
          <span> O visitante/usuário reconhece, ainda, que estes Termos e Condições de Uso do Site do Grupo Yamaha devem
            ser observados e fielmente cumpridos, sob pena de cancelamento de bloqueio de utilização do Site e demais
            medidas cabíveis, caso os mencionados Termos forem violados ou descumpridos. </span>
        </div>

        <div class="flex-vertical-center content-terms-text-item">
          <span class="estricta uppercase bolder"> Segurança </span>
          <span> O Grupo Yamaha Motor do Brasil possui medidas de segurança que protege o seu acesso do Portal, nas
            transações e na troca de informações reservadas com os clientes e visitantes, através do processo de
            criptografia dos dados, utilizando-se do protocolo Secure Socket Layers (SSL). </span>
          <span> Apesar do Grupo Yamaha Motor do Brasil não poder garantir que não ocorram acessos não autorizados,
            tenha certeza de que a empresa é bastante atenta na manutenção da segurança de suas informações pessoais e
            na proteção contra acessos não autorizados, avaliando constantemente o uso de tecnologia apropriada, os
            procedimentos de segurança e a privacidade disponibilizados aos clientes e visitantes do Portal. </span>
        </div>

        <div class="flex-vertical-center content-terms-text-item">
          <span class="estricta uppercase bolder"> Direitos </span>
          <span> O Grupo Yamaha Motor do Brasil assegura a proteção das informações contidas no Portal do Cliente pelas
            leis e normas que regulamentam os direitos autorais, marcas registradas e patentes. </span>
          <span> Todos os textos, imagens, sons e/ou aplicativos exibidos no Portal do Cliente são protegidos pelos
            direitos autorais, não sendo permitidas modificações, reproduções, armazenamentos, transmissões, cópias,
            distribuições ou quaisquer outras formas de utilização para fins comerciais sem o consentimento prévio e
            formal do Grupo Yamaha Motor do Brasil. </span>
          <span> Tentativas de invasão aos sites do Grupo Yamaha Motor do Brasil serão tratadas conforme prescrição
            legal como dano, roubo ou qualquer outra tipificação penal que corresponda às conseqüências da invasão,
            previstas no código penal brasileiro. </span>
        </div>

        <div class="flex-vertical-center content-terms-text-item">
          <span class="estricta uppercase bolder"> Orientação da política de privacidade e mudanças na política de
            privacidade </span>
          <span> Os termos desta política orientam o uso de quaisquer informações recolhidas neste local. A Yamaha
            reserva o direito de modificar a política a qualquer momento, em virtude de alterações na legislação ou nos
            serviços, em decorrência da utilização de novas ferramentas tecnológicas ou, ainda, sempre que, a exclusivo
            critério do Grupo, tais alterações se façam necessárias. Por isso, recomendamos aos nossos usuários que
            verifique esta página com a freqüência que desejar. Mudanças desta política de privacidade entram em efeito
            no momento que forem postadas no site. </span>
          <span> A utilização dos serviços On-line disponibilizados pelo Grupo Yamaha por qualquer usuário implicará em
            expressa aceitação quanto aos termos e condições da Política de Privacidade vigente na data de sua
            utilização. </span>
          <span> Recomendamos aqueles usuários, que não concordem com a Política de Privacidade vigente, a não
            utilização dos serviços deste site, visto que a sua não aceitação por parte do cliente ou ainda, a não
            disponibilização das informações solicitadas, pode impedir a prestação de tais serviços. </span>
          <span> Ressaltamos que novos serviços On-line, disponibilizados pelo Grupo Yamaha, estarão automaticamente
            sujeitos à Política de Privacidade vigente à época de sua utilização. </span>

        </div>

        <span class="flex-vertical-center content-terms-text-item"> Termos e Condições da Política de Privacidade
          Copyright © Grupo Yamaha Motor do Brasil Ltda - 2014 Todos os direitos reservados. </span>

      </div>
    </div>

    <mat-checkbox [(ngModel)]="acceptTerms" [disabled]="!termsRead" data-cy="agreed-terms" color="primary"
      class="flex-horizontal-center content-terms-checkbox custom-frame">
      <span> Li e concordo com os termos e condições acima. </span>
    </mat-checkbox>
  </div>

  <div class="content-buttons">

    <app-button width="100%" color="primary" [disabled]="!form.valid || !acceptTerms || loading"
      [loading]="loading" datacy="confirm-button" (onClick)="post()">
      <span class="text-size"> Cadastrar-se </span>
    </app-button>

    <button mat-button (click)="goToLoginPage()" data-cy="back-button" class="white-bolder cursor-pointer">
      <mat-icon>keyboard_backspace</mat-icon>
      <span class="text-size">Voltar para o login</span>
    </button>

  </div>
</div>
