<div class="content density-lower backdrop-blur">
  <div class="content-header">
    <span class="uppercase estricta bolder content-header-title"> Confirmação de Usuário </span>
    <span class="content-header-subtitle"> E-mail validado com sucesso! </span>
  </div>
  <div class="content-buttons">
    <button mat-button (click)="goToLoginPage()" data-cy="back-button" class="white-bolder cursor-pointer">
      <mat-icon>keyboard_backspace</mat-icon>
      <span class="text-size">Voltar para o login</span>
    </button>

  </div>
</div>
