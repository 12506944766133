import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { DAYS } from "../const/time";

export class CustomValidators {
  static MatchValidator(source: string, target: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const sourceCtrl = control.get(source);
      const targetCtrl = control.get(target);

      return sourceCtrl && targetCtrl && sourceCtrl.value !== targetCtrl.value
        ? { mismatch: true }
        : null;
    };
  }

  static notMatchValidator(source: string, target: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const sourceCtrl = control.get(source);
      const targetCtrl = control.get(target);

      return sourceCtrl && targetCtrl && sourceCtrl.value !== targetCtrl.value
        ? null
        : { notMismatch: true };
    };
  }

  static isValidCpf(checkString?: boolean): ValidatorFn {
    return (control: AbstractControl): Validators => {
      const cpf = control.value;

      if (cpf == null) return null;
      if (cpf == "") return null;
      if (cpf.length > 11) return null;

      if (checkString) {
        if (!/^[0-9]*$/.test(cpf)) {
          return { cpfNotValid: true };
        }
      } else {
        if (!/^[0-9]*$/.test(cpf)) {
          return null;
        }
      }

      if (cpf) {
        let numbers, digits, sum, i, result, equalDigits;
        equalDigits = 1;
        if (cpf.length < 11) {
          return null;
        }

        for (i = 0; i < cpf.length - 1; i++) {
          if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
            equalDigits = 0;
            break;
          }
        }

        if (!equalDigits) {
          numbers = cpf.substring(0, 9);
          digits = cpf.substring(9);
          sum = 0;
          for (i = 10; i > 1; i--) {
            sum += numbers.charAt(10 - i) * i;
          }

          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(0))) {
            return { cpfNotValid: true };
          }
          numbers = cpf.substring(0, 10);
          sum = 0;

          for (i = 11; i > 1; i--) {
            sum += numbers.charAt(11 - i) * i;
          }
          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(1))) {
            return { cpfNotValid: true };
          }
          return null;
        } else {
          return { cpfNotValid: true };
        }
      }
      return null;
    };
  }

  static isValidCNPJ(checkString?: boolean): ValidatorFn {
    return (control: AbstractControl): Validators => {
      const cnpj = control.value;

      if (cnpj == "") return null;
      if (cnpj == null) return null;
      if (cnpj.length != 14) return null;

      if (checkString) {
        if (!/^[0-9]*$/.test(cnpj)) {
          return { cnpjNotValid: true };
        }
      } else {
        if (!/^[0-9]*$/.test(cnpj)) {
          return null;
        }
      }

      // Valida DVs
      let tamanho = cnpj.length - 2;
      let numeros = cnpj.substring(0, tamanho);
      let digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(0)) return { cnpjNotValid: true };

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(1)) return { cnpjNotValid: true };

      return null;
    };
  }

  static isDateGreaterThanToday(): ValidatorFn {
    return (control: AbstractControl): Validators => {
      let today: Date = new Date();
      if (new Date(control.value) > today)
        return { dateGreaterThanToday: true };

      return null;
    };
  }
  static isAgeAtLeast14Years(): ValidatorFn {
    return (control: AbstractControl): Validators => {
      let today: Date = new Date();
      let birthDate: Date = new Date(control.value);
      let ageDifference: number = today.getFullYear() - birthDate.getFullYear();
      let monthDifference: number = today.getMonth() - birthDate.getMonth();
      if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        ageDifference--;
      }
      if (ageDifference >= 14)
        return null;

      return { ageLessThan14Years: true };
    };
  }

  static isDatePriorToToday(): ValidatorFn {
    return (control: AbstractControl): Validators => {
      let today: Date = new Date();
      today.setHours(0, 0, 0, 0);

      if (new Date(control.value) < today)
        return { datePriorToToday: true };

      return null;
    };
  }

  static isStartDateGreaterThanEndDate(
    start: string,
    end: string
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const startControl = control.get(start);
      const endControl = control.get(end);

      if(startControl.value !== null && endControl.value !== null){
        if (startControl.value.toISOString().split('T')[0] > endControl.value.toISOString().split('T')[0]) {
          return { startGreaterThanEnd: true };
        }
      }

      return null;
    };
  }

  static isRequiredWhenFieldIsTrue(
    formGroup: FormGroup,
    fieldName: string
  ): ValidatorFn {
    return (control: AbstractControl) => {
      if (!formGroup) {
        return null;
      }

      return null;
    };
  }

  static isDateLowerThanLimit(minDate: Date) {
    return (control: AbstractControl) => {
      if (control.value < minDate) {
        return { dateLowerThanLimit: true };
      }

      return null;
    };
  }

  static isDateGreaterThanLimit(maxDate: Date) {
    return (control: AbstractControl) => {
      if (control.value > maxDate) {
        return { dateGreaterThanLimit: true };
      }

      return null;
    };
  }

  static periodGreaterThan365Days(
    startDateFieldName: string = "startDate",
    endDateFieldName: string = "endDate"
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let startDate = control.get(startDateFieldName).value as Date;
      let endDate = control.get(endDateFieldName).value as Date;

      if (!startDate || !endDate) {
        return null;
      }

      let daysPassed = (endDate.getTime() - startDate.getTime()) / DAYS;

      if (daysPassed > 365) {
        return { periodGreaterThan365Days: true };
      }

      return null;
    };
  }
}
