import { Injectable } from '@angular/core';
import { BaseService } from '../@base/base.service';
import { Observable, catchError, mergeMap, of, switchMap } from 'rxjs';
import { Auth, CheckDateExpire, ConfirmAccount, EmailConfirmAccount, EmailRegisterAccount, EmailRegisterAccountResponse, ExternalAuth, GetUserEmailRequest, NewAccount, NewAccountOld, NewAccountResponse, NewPassword, ResetExpiredPassword, ResetPassword, ResetPasswordResponse, VerifyCpfCnpjIsValidRequest, VerifyCpfCnpjIsValidResponse } from '../models/auth/auth';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { CurrentUser, User } from '../models/user/user';
import { Notification } from 'src/app/models/notification/notification';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),

  withCredentials: true,
  observe: 'response' as 'response'
};

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService<any> {

  constructor() {
    super()
    this.path = "gateway/user"
  }

  public document:string;

  public externalLogin(auth: ExternalAuth): Observable<any> {
    return this.http.post(this.api + "account/signin/external", auth)
      .pipe(mergeMap((response) => {
        this.getAntiForgeryToken();
        return of (response)
      }));
  }

  public login(auth: Auth): Observable<any> {
    return this.http.post(this.api + "account/signin", auth, { headers: this.addHeaders(true), withCredentials: true })
      .pipe(mergeMap((response) => {
        this.getAntiForgeryToken()
        return of(response)
      }
      ));
  }

  public invalidate(): Observable<any> {
    return this.http.post(this.api + "account/invalidate", null);
  }

  public createCache(): Observable<any> {
    return this.http.post(this.api + "account/createCache", null);
  }

  public getAntiForgeryToken(): Observable<any> {
    return this.http.get(this.api + 'account/antiforgerytoken', httpOptions)
  }

  public logout(): Observable<any> {
    return this.http.get(this.api + "account/logout", { headers: this.addHeaders(true), withCredentials: true })
  }

  public register(newAccount: NewAccount): Observable<any> {
    return this.http.post(this.api + "account/register", newAccount)
  }

  public registerOld(newAccount: NewAccountOld): Observable<any> {
    return this.http.post(this.api + "account/register-old", newAccount)
  }

  public getCurrentUser(): Observable<CurrentUser> {
    return this.http.get<CurrentUser>(this.api + this.path + "/current", { withCredentials: true })
  }

  public resetPassword(resetPassword: ResetPassword): Observable<ResetPasswordResponse> {
    return this.http.post<ResetPasswordResponse>(this.api + this.path + "/reset-password", resetPassword)
  }

  public resetExpiredPassword(resetPassword: ResetExpiredPassword): Observable<User> {
    return this.http.post<User>(this.api + this.path + "/reset-expired-password", resetPassword)
  }

  public checkGuid(guid: string): Observable<any> {
    return this.http.get<any>(this.api + this.path + "/reset-password/" + guid)
  }

  public newPassword(newPassword: NewPassword): Observable<any> {
    return this.http.post<any>(this.api + this.path + "/reset-password/apply", newPassword)
  }

  public registerPasswordAccount(newPassword: NewPassword): Observable<any> {
    return this.http.post<any>(this.api + this.path + "/register-password-account/apply", newPassword)
  }

  public newLogout(): Observable<any> {
    return this.http.put<any>(this.api + this.path + "/new-logout", null)
      .pipe(
        switchMap(() => {
          return this.logout();
        }),
        catchError(error => {
          return this.logout();
        })
      );
  }

  public newLogoutFetch(): void {
    this.fetchRequest(this.api + this.path + "/new-logout", 'PUT', {"withCredentials": "true"});
  }

  public GetUserEmail(userName: GetUserEmailRequest): Observable<any> {
    return this.http.post(this.api + this.path + "/get-user-email", {document: userName} );
  }

  public getDaysToExpirePassword(auth: Auth): Observable<CheckDateExpire> {
    return this.http.post<CheckDateExpire>(this.api + this.path + '/days-password-expire', auth)
  }

  public verifyCpfCnpjIsValid(documentCpfCnpj: VerifyCpfCnpjIsValidRequest): Observable<VerifyCpfCnpjIsValidResponse[]> {
    return this.http.post<VerifyCpfCnpjIsValidResponse[]>(this.api + this.path + '/verify-cpf-cnpj-valid',documentCpfCnpj)
  }

  public sendEmailRegisterAccount(emailRegisterAccount: EmailRegisterAccount): Observable<EmailRegisterAccountResponse> {
    return this.http.post<EmailRegisterAccountResponse>(this.api + this.path + "/send-email-register-account", emailRegisterAccount)
  }
  public sendEmailConfirmAccount(emailConfirmAccount: EmailConfirmAccount){
    return this.http.post(this.api + this.path + "/send-email-confirm-account", emailConfirmAccount)
  }
  public confirmAccount(confirmAccount: ConfirmAccount){
    return this.http.post(this.api + this.path + "/confirm-account", confirmAccount)
  }

}
