import { BaseComponent } from './../../../@base/base.component';
import { Component, Inject, OnInit, inject } from "@angular/core";
import { Option } from "src/app/models/option";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Subscription, firstValueFrom } from 'rxjs';
import { CustomValidators } from 'src/app/form/custom-validators';
import { BankService } from 'src/app/services/bank.service';
import { Contract } from 'src/app/models/bank/contract';
import { Statement } from 'src/app/models/bank/statement';
import { IncidentRequest, IncidentResponse } from 'src/app/models/incident/incident';
import { IncidentService } from 'src/app/services/incident.service';
import { ModalConfirmComponent } from 'src/app/components/modal/modal-confirm/modal-confirm.component';
import { LogService } from "src/app/services/log.service";
import { EnumProduct } from "src/app/enum/products";
import { EnumReportIncident } from "src/app/enum/report-incident";
import { DOCUMENT } from "@angular/common";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { ModalSucess } from "src/app/components/modal/modal-success/modal-success.component";
import { MatSelectChange } from "@angular/material/select";
import { HttpErrorResponse } from '@angular/common/http';
import { EmailConfirmAccount, VerifyCpfCnpjIsValidRequest } from 'src/app/models/auth/auth';
@Component({
  selector: 'app-modal-login-confirm-email',
  templateUrl: './modal-login-confirm-email.component.html',
  styleUrls: ['./modal-login-confirm-email.component.scss']
})
export class ModalLoginConfirmEmailComponent extends ModalComponent<ModalLoginConfirmEmailComponent> implements OnInit
{
  public isBtnEnviarLoading: boolean = false;
  public isLoadingData: boolean = false;

  public payments: Statement[];
  public advances: Statement[];
  public debts: Statement[];

  public subject: Option;
  public documentNumber: string;
  public form: FormGroup;

  public contractSelected: Contract;
  public $contractSubscriber: Subscription;

  public today = new Date();
  public loading: boolean = false;
  public loadingVerify: boolean = false;
  public emails: string[];
  public hiddenEmails: string[];
  public selectedEmail: string;
  public email: string = '';
  public emailValidado: boolean;

  BaseComponent = new BaseComponent();

  get equalValuesError() {
    return (
      this.form.hasError("notMismatch") &&
      this.form.get("replaceInstallment").value > 0 &&
      this.form.get("wrongPayedInstallment").value > 0
    );
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {documentNumber: any},
    private formBuilder: FormBuilder,
    private bankService: BankService,
    private incidentService: IncidentService,
    private dialog: MatDialog
  ) {
    super();
    this.documentNumber = data.documentNumber; // Documento que irá pegar de login
  }

  ngOnInit(): void {
    this.createForm();
    this.verifyCpfCnpj();
  }

  public createForm(): void {
    this.form = this.formBuilder.group({
      email:[null, [Validators.required, Validators.email]]
    });
  }

  private createIncidentObject(): IncidentRequest {
    return {
      subject: 11,
      description:
        "SOLICITACAO REALIZADA VIA SITE TRANSACIONAL - Inversão de Pagamento solicitada para as alterações de:\n" +
        `CPF/CNPJ: ${this.documentNumber}\n`+
        `Contrato: ${this.contractSelected.contractNumber}\n`+
        "Parcela paga por engano: "+ this.form.get("wrongPayedInstallment").value + ".\nParcela correta: " + this.form.get("replaceInstallment").value + ".",
      product: 1,
    };
  }


  public async onClickBtnSubmit(event?: any) {
    this.isBtnEnviarLoading = true;

    if (!this.form.valid) {
      return;
    }

    let index = this.hiddenEmails.indexOf(this.selectedEmail);
    if (index !== -1) {
      this.emailValidado = this.email === this.emails[index];
    } else {
      this.emailValidado = false;
    }

    let emailConfirm: EmailConfirmAccount = {
      document: this.data.documentNumber.value,
      email: this.form.get('email').value
    }

    let messageModalSuccess: ModalSucess = {
      title: "Atenção",
      message: "Prezado cliente, Se caso o e-mail digitado for o mesmo do campo selecionado, um link de confirmação será enviado na sua caixa de entrada.",
      style: {width: '900px'}
    }
    if(this.emailValidado){
      await firstValueFrom(this.BaseComponent.authService.sendEmailConfirmAccount(emailConfirm))
      .then(() => {
        this.onSubmit(messageModalSuccess);
      })
      .finally(() => {
        this.isBtnEnviarLoading = false;
      })
    }
  }

  public async onClickBtnClose(): Promise<void>{

    if(this.form.dirty){
      firstValueFrom(this.dialog.open(ModalConfirmComponent, {
        maxWidth: "900px",
        data: "Tem certeza que deseja cancelar?"
      })
      .afterClosed())
      .then((resp: boolean) => resp ? this.onClose(false) : null)
    } else {
      this.onClose(false)
    }
  }

  onEmailSelect(event: MatSelectChange) {
    this.selectedEmail = event.value;
    this.onInputChange()
  }

  onInputChange() {
    const index = this.hiddenEmails.indexOf(this.selectedEmail);
    if (index !== -1) {
      this.emailValidado = this.email === this.emails[index];
    } else {
      this.emailValidado = false;
    }
  }

  notRecognizeEmail(){
    this.BaseComponent.openDialogMessage(
      "Caso não reconheça nenhum e-mail(s) vinculado(s), favor entrar em contato com a nossa Central de Relacionamento (11) 2088-7700",
    );
  }

  get labelDocument() {
    return this.form.get('document').value?.length > 11 ? 'CNPJ' : 'CPF';
  }

  public verifyCpfCnpj(){
    this.loadingVerify = true
    this.emails = null;
    let documentCpfCnpj = this.data.documentNumber;

    documentCpfCnpj?.markAsTouched();
    documentCpfCnpj?.updateValueAndValidity();

    let verify: VerifyCpfCnpjIsValidRequest = {
      document : documentCpfCnpj.value,
      isConfirmAccount : true
    }

    if(documentCpfCnpj.valid){
      firstValueFrom(this.BaseComponent.authService.verifyCpfCnpjIsValid(verify))
      .then((resp) => {
        if(resp){
          this.emails = resp.map(response => response.email).flat();
          this.hiddenEmails = resp.map(response => response.hiddenEmail).flat();
        }
      })
      .finally(() => (this.loadingVerify = false))
      .catch((error: HttpErrorResponse) => {
        if (error.status !== 500) {
          this.BaseComponent.openDialogError(error.error);
        }
      });
    }
    else{
      this.loadingVerify = false;
    }
  }
}
